
import { defineComponent } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'

export default defineComponent({
  components: {
    ChatsSettingsPage,
    DetailsHero,
    InfoCard,
    InfoCardRow,
    TmButton,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: '' },
    ]

    return {
      breadcrumbs,
    }
  },
})
